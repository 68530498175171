<template>
    <el-dialog
        :title="mode == 'add' ? '新增会员' : '编辑会员'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :inline="true" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="时长" prop="duration">
                <el-input v-model="form.duration" placeholder="请输入会员时间" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="原价" prop="price">
                <el-input v-model="form.price" placeholder="请输入原价" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="实际价格" prop="sale">
                <el-input v-model="form.sale" placeholder="请输入实际价格" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="是否推荐" prop="recommendFlag">
                <el-radio-group v-model="form.recommendFlag">
                    <el-radio :label="true">推荐</el-radio>
                    <el-radio :label="false">不推荐</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否优惠" prop="saleFlag">
                <el-radio-group v-model="form.saleFlag">
                    <el-radio :label="true">优惠</el-radio>
                    <el-radio :label="false">不优惠</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否启用" prop="enableFlag">
                <el-radio-group v-model="form.enableFlag">
                    <el-radio :label="true">启用</el-radio>
                    <el-radio :label="false">禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="seq">
                <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                duration: '',
                price: '',
                sale: '',
                recommendFlag: true,
                saleFlag: true,
                enableFlag: true,
                seq: 1
            },
            rules: {
                duration: [
                    { required: true, message: '请输入会员时间', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入会员原价', trigger: 'blur' }
                ],
                sale: [
                    { required: true, message: '请输入会员实际价格', trigger: 'blur' }
                ],
                recommendFlag: [
                    { required: true, message: '请选择是否推荐', trigger: 'change' }
                ],
                saleFlag: [
                    { required: true, message: '请选择是否优惠', trigger: 'change' }
                ],
                enableFlag: [
                    { required: true, message: '请选择是否启用', trigger: 'change' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    duration: '',
                    price: '',
                    sale: '',
                    recommendFlag: true,
                    saleFlag: true,
                    enableFlag: true,
                    seq: 1
                }
            } else {
                this.getDetailInfo(row.id)
            }

            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getDetailInfo(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'getMemberDetail'
            }).then(res=> {
                if(res.code == 0) {
                    this.form = {
                        id: res.data.id,
                        duration: res.data.duration,
                        price: res.data.price,
                        sale: res.data.sale,
                        recommendFlag: res.data.recommendFlag,
                        saleFlag: res.data.saleFlag,
                        enableFlag: res.data.enableFlag,
                        seq: res.data.seq
                    }
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addMember' : 'editMember',
                        listInterfaceName: 'getMemberList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    .el-form-item {
        width: 50%;
        margin-right: 0;
    }
}
</style>